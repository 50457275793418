*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Lato', 'PT Sans', sans-serif;
}

.OrganeGestion_Intro_title{
	margin-top: 70px;
}

.OrganeGestion_Intro_title h1{
	background-color: #ccffe3;
	font-weight: bold;
	color: #286f4e;
	text-align: center;
	font-size: 3.5rem;
	padding: 150px 150px 300px 150px;
}

.OrganeGestion_Intro_img{
	display: grid;
	justify-content: center;
	align-items: center;
}

.OrganeGestion_Intro_img img{
	width: 900px;
	height: auto;
	margin-top: -150px;
}

.OrganeGestion_exp .OrganeGestion_exp_Intro{
	display: grid;
	justify-content: center;
	align-items: center;
}

.OrganeGestion_exp .OrganeGestion_exp_Intro p{
	text-align: center;
	color: #286f4e;
	font-weight: bold;
	width: 600px;
	margin: 50px 0 0 0;;
}

.OrganeGestion_exp_Buttons{
	display: flex;
	justify-content: center;
	align-items: center;
}

.OrganeGestion_exp_Buttons{
	margin-top: 50px;
}

.OrganeGestion_exp_Buttons .button_1{
	background-color: #286f4e;
	color: white;
	text-decoration: none;
	font-weight: bold;
	padding: 10px;
	cursor: pointer;
}

.OrganeGestion_exp_Buttons .button_2{
	background-color: #286f4e;
	color: white;
	text-decoration: none;
	font-weight: bold;
	padding: 10px;
	margin-left: 10px;
	cursor: pointer;
}

.OrganeGestion_Conseil{
	margin-top: 50px;
	margin-bottom: 50px;

}

.OrganeGestion_Conseil_title h2{
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	color: #286f4e;
}

.OrganeGestion_Coord{
	margin-top: 50px;
	margin-bottom: 50px;

}

.OrganeGestion_Exp{
	display: grid;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-top: 50px;
	border-bottom: 2px solid #286f4e;
	border-radius: 10px;
}

.OrganeGestion_Img img{
	width: 120px;
	height: auto;
	border-radius: 50%;
}

.OrganeGestion_Exp .OrganeGestion_Function h3, p{
	color: #286f4e;
}

.OrganeGestion_Exp .OrganeGestion_Function p{
	font-weight: bold;
	/* text-transform: uppercase; */
	font-size: 1.2rem;

}

.OrganeGestion_Conseil_Identity{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	gap: 15px;
	padding: 30px 150px 0 150px;
}

@media screen and (max-width: 992px) {
	.OrganeGestion_Intro_title h1{
		background-color: #ccffe3;
		color: #286f4e;
		text-align: center;
		font-size: 2rem;
		padding: 150px 10px 300px 10px;
	}

	
	.OrganeGestion_Intro_img{
		display: grid;
		justify-content: center;
		align-items: center;
	}

	
	.OrganeGestion_Intro_img{
		padding: 10px;
		display: grid;
		justify-content: center;
		align-items: center;
	}

	.OrganeGestion_Intro_img img{
		width: 100%;
		height: auto;
		margin: -150px 20px 20px 0;
	}

	.OrganeGestion_exp .OrganeGestion_exp_Intro p{
	text-align: center;
	color: #286f4e;
	font-weight: bold;
	width: 100%;
	font-size: 0.8rem;
	margin: 50px 0 0 0;
	padding: 0 50px 0 50px;
	}

	
	.OrganeGestion_exp_Buttons{
		display: grid;
		justify-content: center;
		align-items: center;
	}

		
	.OrganeGestion_exp_Buttons .button_1{
		background-color: #286f4e;
		color: white;
		text-align: center;
		text-decoration: none;
		font-weight: bold;
		padding: 10px;
	}

	.OrganeGestion_exp_Buttons .button_2{
		background-color: #286f4e;
		color: white;
		text-align: center;
		text-decoration: none;
		font-weight: bold;
		padding: 10px;
		margin: 10px 0 0  0;
	}

	
	.OrganeGestion_Conseil_Identity{
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		gap: 15px;
		padding: 30px 10px 0 10px;
	}
}