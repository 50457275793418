* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Lato', 'PT Sans', sans-serif;
}

.commissions_Intro {
	margin-top: 70px;
}

.commissions_Intro h1 {
	background-color: #ccffe3;
	color: #286f4e;
	font-weight: bold;
	text-align: center;
	font-size: 3.5rem;
	padding: 150px 150px 150px 150px;
}

.commissions_textes {
	color: #286f4e;
	margin: 50px 300px 50px 300px;
}

.commissions_textes h1 {
	font-weight: bold;
	color: #286f4e;
	font-size: 1.2rem;
	border: 2px solid #286f4e;
	text-transform: uppercase;
	padding: 20px 20px 20px 20px;
	box-shadow: 0px 1px 2px 2px #888888;
	border-radius: 10px;
}


@media screen and (max-width: 992px) {

	.commissions_Intro h1 {
		font-size: 2.5rem;
		padding: 150px 10px 150px 10px;
	}

	.commissions_textes {
		color: #286f4e;
		margin: 50px 20px 50px 20px;
	}
}