*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Lato', 'PT Sans', sans-serif;
}

.Approches_Intro{
	margin-top: 70px;
}

.Approches_Intro h1{
	background-color: #286f4e;
	color: white;
	font-weight: bold;
	text-align: center;
	font-size: 3.5rem;
	padding: 150px 150px 300px 150px;
}

.Approches_textes_box{
	display: flex;
	background-color: #ccffe3;
	padding: 100px;
	border-radius: 30px;
	margin: -150px 50px 80px 50px;
}

.Approches_textes_box .Approches_box{
	color: #286f4e;
	width: 100%;
	height: auto;
	padding: 30px;
}

.numbers{
	display: grid;
	justify-content: center;
	align-items: center;
}

.numbers h1{
	background-color: #286f4e;
	color: white;
	font-weight: bold;
	padding: 10px;
	width: 60px;
	text-align: center;
	font-size: 1.5rem;
	height: auto;
	border-radius: 50%;
}

.Approches_textes_box .Approches_box p{
	text-align: center;
}

.Approches_textes_box .Approches_box .Approche p{
	font-weight: bold;
}

.Approches_textes_box .Approches_box .Approche{
	margin-top: 20px;
}

.Approches_textes_box .Approches_box .Approche_exp{
	margin-top: 20px;
}

.Approches_textes_box .Approches_box .Approche_exp p{
	font-size: 0.8rem;
}

@media screen and (max-width: 992px) {

	.Approches_Intro h1{
		font-size: 2.5rem;
		padding: 150px 10px 150px 10px;
	}
	
	.Approches_textes_box{
		display: grid;
		background-color: #ccffe3;
		padding: 10px;
		border-radius: 25px;
		margin: -80px 20px 20px 20px;
	}

	.Approches_textes_box .Approches_box{
		color: #286f4e;
		padding: 20px;
	}
}