*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Lato', 'PT Sans', sans-serif;
}

.VisionMission_Intro{
	margin-top: 70px;
}

.VisionMission_Intro h1{
	background-color: #ccffe3;
	font-weight: bold;
	color: #286f4e;
	text-align: center;
	font-size: 3.5rem;
	padding: 150px 150px 150px 150px;
}

.VisionMission_textes_box{
	margin: 50px 0 50px 0;
	display: grid;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.VisionMission_textes{
	width: 700px;
	height: auto;
	text-align: justify;
}

.VisionMission_textes p{
	width: 700px;
	height: auto;
	color: #00213d;
	text-align: justify;
}

.VisionMission_textes h1{
	margin: 20px 0 20px 0;
	font-weight: bold;
	color: #00213d;
}

@media screen and (max-width: 992px) {

	.VisionMission_Intro h1{
		font-size: 2.5rem;
		padding: 150px 10px 150px 10px;
	}

	.VisionMission_textes_box{
		width: 100%;
		padding: 50px 15px 50px 15px;
		display: grid;
		justify-content: center;
		align-items: center;
	}

		
	.VisionMission_textes{
		width: 100%;
		height: auto;
		text-align: left;
	}

	.VisionMission_textes p{
		width: 100%;
		height: auto;
		color: #00213d;
		text-align: left;
	}
}