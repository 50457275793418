.dropdown-menu-som {
	width: 250px;
	position: absolute;
	margin-left: 10px;
	top: 60px;
	list-style: none;
	text-align: start;
  }
  
  
  .dropdown-menu-som li{
	cursor: pointer;
	font-size: 0.8rem;
	font-weight: bold;
	/* box-shadow: #3CB371; */
	background-color: #f8f8f8;
  }
  
  .dropdown-menu-som.clicked {
	display: none;
  }
  
  .dropdown-link {
	display: block;
	height: 100%;
	width: 100%;
	text-decoration: none;
	color: #3CB371;
	padding: 16px;
  }
  
  .dropdown-link:hover {
	color: #3CB371;
	border-left: 5px solid #3CB371;
	background-color: white;
  }
  
  @media screen and (max-width: 960px) {
	.dropdown-menu-som {
	  width: 100%;
	  padding: 0 10px;
	  top: 146px;
	  margin-left: 0px;
	}
	.dropdown-menu-som li{
	  cursor: pointer;
	  font-size: 0.8rem;
	  font-weight: bold;
	  background-color: #ffffff;
	}
  }