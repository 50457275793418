*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Lato', 'PT Sans', sans-serif;
}

.zone_Intro{
	margin-top: 70px;
}


.zone_Intro h1{
	background-color: #ccffe3;
	font-weight: bold;
	text-align: center;
	font-size: 3.5rem;
	padding: 100px 0 100px 0;
}

.zone_block{
	display: grid;
	justify-content: center;
	align-items: center;
	padding: 80px 0 80px 0;
}

.zone_boxes{
	border-top: 2px solid #286f4e;
	padding: 10px;
	margin-top: 10px;
	display: flex;
}

.zone_boxes .zone_image img{
	width: 430px;
	height: 300px;
}

.zone_boxes .zone_textes{
	color: #286f4e;
	width: 400px;
	height: auto;
	padding: 20px 0 0 20px;
}

.zone_boxes #zone_text_inverse{
	color: #286f4e;
	width: 400px;
	height: auto;
	padding: 20px 20px 0 0;
}

.zone_boxes .zone_textes h2{
	padding: 0 0 10px 0;
	font-weight: bold;
	color: #286f4e;
	font-size: 2rem;
	text-transform: uppercase;
}




@media screen and (max-width: 992px) {
	
	.zone_Intro h1{
		font-size: 2.5rem;
	}

	.zone_block{
		padding: 80px 10px 80px 10px;
	}

	.zone_boxes{
		border-top: 2px solid #286f4e;
		padding: 0px;
		width: 100%;
		display: grid;
	}

	.zone_boxes .zone_textes{
		color: #286f4e;
		width: auto;
		height: auto;
		padding: 20px 0 0 0;
	}
	
	.zone_boxes #zone_text_inverse{
		color: #286f4e;
		width: auto;
		height: auto;
		padding: 20px 0 0 0;
	}

	.zone_boxes .zone_image img{
		width: 100%;
		height: 300px;
		margin-top: 20px;
	}
}