*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Lato', 'PT Sans', sans-serif;
}

.Resultats_Intro{
	margin-top: 70px;
}

.Resultats_Intro{
	background-color: #3cb371;
	display: flex;
	justify-content: space-between;
}

.Resultats_Intro .Intro_text{
	height: auto;
	margin: 100px 0 0 100px;
}

.Resultats_Intro h1{
	color: white;
	font-size: 6rem;
	font-weight: bold;
	margin: 0;
}

.Resultats_Intro p{
	color: white;
	width: 600px;
	height: auto;
	margin-top: 0;
}

.Resultats_Intro img{
	width: 500px;
	height: auto;
}

.Resultats_Boxes{
	display: grid;
	justify-content: center;
	align-items: center;
	padding-bottom: 80px;
}

.Resultats_Boxes .Resultats_Box{
	width: 500px;
	height: auto;
	margin: 80px 0 0 0;
	border: 2px solid hsl(0, 0%, 16%);
}

.Resultats_Boxes .Resultats_Box .Resulats_Header{
	background-color: #03213c;
	color: white;
	padding: 20px;
}
.Resultats_Boxes .Resultats_Box .Resulats_Header h2{
	color: white;
	margin-top: 10px;
	font-weight: bold;
}

.Resultats_Boxes .Resultats_Box .Resulats_Header p{
	color: white;
	margin-top: 10px;
}

.Resultats_Boxes .Resultats_Box .Resulats_body{
	color: white;
	padding: 20px;
}

.Resultats_Quote{
	display: flex;
	background-color: #3cb371;
}

.Resultats_Quote img{
	width: 50%;
	height: auto;
}

.Resultats_Quote h1{
	background-color: #3cb371;
	color: white;
	font-weight: bold;
	display: grid;
	font-size: 3rem;
	padding: 0 100px 0 100px;
	justify-content: center;
	align-items: center;
}


@media screen and (max-width: 992px) {
	
	.Resultats_Intro h1{
		color: white;
		font-size: 2.5rem;
	}

	
	.Resultats_Intro img{
		display: none;
	}

	.Resultats_Intro .Intro_text{
		height: auto;
		margin: 50px 50px 50px 50px;
	}

	.Resultats_Intro p{
		color: white;
		width: 100%;
		height: auto;
		font-size: 0.8rem;
	}

	.Resultats_Boxes .Resultats_Box{
		width: 100%;
		height: auto;
		border: 2px solid hsl(0, 0%, 16%);
	}

	.Resultats_Boxes{
		display: grid;
		justify-content: center;
		align-items: center;
		padding: 0 10px 80px 10px;
	}

	
	.Resultats_Boxes .Resultats_Box .Resulats_Header p{
		font-size: 0.8rem;
	}

	
	.Resultats_Boxes .Resultats_Box .Resulats_body p{
		font-size: 0.9rem;
	}

	
	.Resultats_Quote{
		display: grid;
	}

	.Resultats_Quote img{
		width: 100%;
		height: auto;
	}

	.Resultats_Quote h1{
		background-color: #3cb371;
		color: white;
		display: block;
		font-size: 3rem;
		padding: 100px 70px 100px 70px;
		justify-content: center;
		align-items: center;
	}
}



@media screen and (max-width: 1298px) and (min-width: 992px) {
	
	.Resultats_Intro h1{
		color: white;
		font-size: 5rem; 
	}
}