.Contact_color_top{
    width: 100%;
    height: 600px;
    background-color: gray;
}

.Contact_color_bottom{
    width: 100%;
    height: 50px;
    background-color: gray;
}

.Contact_contents_container{
    justify-content: center;
    align-items: center;
    display: flex;
}
.contact_container_detail{
    width: 70%;
    height: auto;
    background-color: white;
    border:1px solid gray;
    padding: 20px;
    margin-top: -20%;
    margin-bottom: 80px;
    box-shadow: gray 0 0 0 2px;
}


.contact_address {
    justify-content: center;
    align-items: center;
    display: flex;
}

.contact_address p{
    width: 50%;
    height: auto;
    text-align: center;
    font-weight: bold;
    margin-top: 80px;
    color: gray;
}


.contact_img .contact_logo{
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 50px;
}

.contact_img img {
    width: 20%;
    height: auto;
}

.contact_links_txt{
    margin-top: 80px;
    margin-bottom: 80px;
}

.contact_links_txt p{
    width: 100%;
    height: auto;
    text-align: center;
    font-weight: bold;
    color: gray;
}

.contact_links_icons{
	padding: 10px;
	display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.contact_links_icons .address_link_icon{
	color: gray;
	font-size: 1.5rem;
    margin-left: 10px;
}

.contact_links_icons .address_link_icon i:hover{
	color: #008F40;
}


@media screen and (max-width: 960px) {
    .Contact_color_top{
        width: 100%;
        height: 300px;
        background-color: gray;
    }

    .contact_container_detail{
        width: 80%;
        height: auto;
        background-color: white;
        border:1px solid gray;
        padding: 20px;
        margin-top: -35%;
        margin-bottom: 80px;
        box-shadow: gray 0 0 0 2px;
    }
    
    
    .contact_img img {
        width: 40%;
        height: auto;
    }

    
    .contact_address p{
        width: 80%;
        height: auto;
        text-align: center;
        font-weight: bold;
        margin-top: 80px;
        color: gray;
    }
}