*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'PT Sans', sans-serif;
}


.header-details {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	font-size: 1.2rem;
	position: fixed;
	top: 0;
	background-color: white;
	z-index: 99999;
  }

  .header-logo .logoimg {
	width: auto;
	height: 60px;
	margin-left: 100px;
	cursor: pointer;
  }
  
  .header-menu {
	display: flex;
	justify-content: space-between;
  }

  .header-item {
	display: flex;
  }
  
  .header-links {
	color: #3CB371;
	text-decoration: none;
	padding: 0.5rem 0rem;
	font-size: 0.8rem;
	font-weight: bold;
	margin-left: 50px;
	margin-top: 20px;
  }
  
  .header-links:hover{
	color: #3CB371;
	cursor: pointer;
	transition: all 0.2s ease-out;
	border-bottom: 3px solid #3CB371;
  }

  .header-links:hover i{
	display: none;
	transition: all 0.2s ease-out;
	border: none;
  }
  
  .fa-bars {
	color: #008F40;
  }
  
  .nav-links-mobile {
	display: none;
  }
  
  .menu-icon {
	display: none;
  }

  .Button_container{
	display: flex;
	justify-content: right;
	align-items: center;
  }

  .donation_btn {
	padding: 20px 20px;
	width: 100%;
	height: 70px;
	position: right;
	outline: none;
	overflow: none;
	border: none;
	font-size: 1.5rem;
	font-weight: bold;
	color: #fff;
	cursor: pointer;
	background-color: #3CB371;
  }
  
  .donation_btn:hover {
	background-color: #008F40;
  }


  .Button_container .button_container_content .LanguageSelector{
	padding: 5px;
	font-size: 1rem;
	background: none;
	border: none;
	font-weight: bold;
}

.Button_container .button_container_content{
	display: flex;
	justify-content: right;
	align-items: center;
	gap: 10px;
	width: 100%;
  }


  @media screen and (max-width: 960px) {

	.header-menu {
	  display: block;
	  width: 100%;
	  /* height: 60vh; */
	  position: absolute;
	  background-color: #f8f8f8;
	  top: 70px;
	  left: -100%;
	  opacity: 1;
	  padding: 20px 0 20px 0;
	  transition: all 0.5s ease;
	  z-index: 1;
	}
  
	.header-menu.active {
	  left: 0;
	  opacity: 1;
	  transition: all 0.5s ease;
	  z-index: 1;
	}
  
	.header-links {
	  text-align: center;
	  padding: 1.3rem;
	  width: 100%;
	  display: table;
	  margin: 0 10px 0 10px;
	}

	.header-links:hover{
		border-bottom: 2px solid #3CB371;
	  }

	.header-logo .logoimg {
		/* width: auto; */
		/* height: 40px; */
		margin-left: 50px;
	}
	.header-details .logo {
		width: 100%;
		justify-content: right;
		align-items: center;
		display: grid;
	}
  
	.menu-icon {
	  display: grid;
	  align-items: center;
	  position: fixed;
	  color: #3CB371;
	  top: 0;
	  left: 50PX;
	  transform: translate(-100%, 60%);
	  font-size: 1.8rem;
	  cursor: pointer;
	}
  
	.fa-times {
	  color: #3CB371;
	  font-size: 2rem;
	}

	.fa-bars {
	  color: #3CB371;
	  font-size: 2rem;
	}
  
	.header-links-mobile {
	  display: block;
	  text-align: center;
	  padding: 1.5rem;
	  margin: 2rem auto;
	  border-radius: 4px;
	  width: 80%;
	  background: #1888ff;
	  text-decoration: none;
	  color: #fff;
	  font-size: 1.5rem;
	}
  
	.header-links-mobile:hover {
	  background: #fff;
	  color: #1888ff;
	  transition: 250ms;
	}

	.donation_btn {
		padding: 15px 10px;
		width: 100%;
		height: 70px;
		position: right;
		outline: none;
		overflow: none;
		border: none;
		font-size: 0.8rem;
		font-weight: bold;
		color: #fff;
		cursor: pointer;
		background-color: #6bdb9d;
	  }

	  .Button_container {
		width: 100%;
	}
  }
