*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Lato', 'PT Sans', sans-serif;
}

.programmes_Intro{
	margin-top: 70px;
}

.programmes_boxes{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	grid-gap: 16px;
}

.programmes_Intro h1{
	background-color: #286f4e;
	font-weight: bold;
	color: white;
	text-align: center;
	font-size: 3.5rem;
	padding: 150px 150px 150px 150px;
}

.programmes_boxes{
	margin: 100px 50px 100px 50px;
}
.programmes_box{
	margin-top: 70px;
	border-top: 10px solid #286f4e;
	background-color: rgb(240, 240, 240);
}

.programmes_box_header{
	padding: 20px;
}

.programmes_box_title{
	width: 100%;
	height: 300px;
	margin: -70px 10px 10px 0px;
}

#box1{
	background-image: url('../assets/DSC00024.JPG');
	background-repeat: no-repeat;
	object-fit: fill;
	display: grid;
	align-items: center;
	justify-content: center;
	background-size: cover;
}

#box2{
	background-image: url('../assets/DSC09952.JPG');
	background-repeat: no-repeat;
	object-fit: fill;
	display: grid;
	align-items: center;
	justify-content: center;
	background-size: cover;
}

#box3{
	width: 100%;
	background-image: url('../assets/1623835884090.JPEG');
	background-repeat: no-repeat;
	opacity: 80px;
	object-fit: fill;
	display: grid;
	background-color: #286f4e;
	align-items: center;
	justify-content: center;
	background-size: cover;
}

#box4{
	width: 100%;
	background-image: url('../assets/IMG_5336.JPG');
	background-repeat: no-repeat;
	object-fit: fill;
	display: grid;
	align-items: center;
	justify-content: center;
	background-size: cover;
}

#box5{
	width: 100%;
	background-image: url('../assets/IMG-20210701-WA0060.jpg');
	background-repeat: no-repeat;
	object-fit: fill;
	display: grid;
	align-items: center;
	justify-content: center;
	background-size: cover;
}

.programmes_box_title h2{
	padding: 20px;
	text-align: center;
	font-weight: bold;
	font-size: 1.5rem;
	color: white;
}

.programmes_box_txt{
	padding: 10px 50px 10px 50px;
	width: 100%;
}

.programmes_box_txt h2{
	font-weight: bold;
	color: #286f4e;
	text-transform: uppercase;
}

@media screen and (max-width: 992px) {
	.programmes_boxes{
		display: block;
		justify-content: space-around;
	}

	.programmes_Intro h1{
		background-color: #286f4e;
		color: white;
		text-align: center;
		font-size: 2.5rem;
		padding: 150px 10px 150px 10px;
	
	}

	.programmes_boxes{
		margin: 150px 20px 100px 20px;
	}

	.programmes_box{
		margin: 100px 10px 100px 10px;
		border-top: 10px solid #286f4e;
		background-color: rgb(209, 209, 209);
	}

	.programmes_box_header{
		padding: 20px;
	}

	.programmes_box_title{
		width: 100%;
		height: 300px;
		margin: -70px 10px 10px 0px;
	}
}